
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';
import MarketsClusterService, { ClusterMarketsServiceS } from '../../cluster-markets.service';
import ProviderFilter from './filters/provider.vue';

@Component({
    components: {
        ProviderFilter,
        ExcelBtn,
    },
})
export default class MarketsClusterActions extends Vue {
    @inject(ClusterMarketsServiceS) public marketsClusterService!: MarketsClusterService;

    downloadAction() {
        return this.marketsClusterService.downloadExcel();
    }
}
