

import { Component, Mixins } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import MarketsClusterOtelMixin from '@/modules/open-telemetry/mixins/markets/markets-cluster-otel.mixin';
import MarketsHeader from '../components/markets/markets-cluster-header.vue';
import MarketsActions from '../components/markets/markets-cluster-actions.vue';
import MarketsList from '../components/markets/list/markets-cluster-list.vue';
import ClusterPageMixin from '../mixins/cluster-page.mixin';

@Component({
    components: {
        PageWrapper,
        MarketsHeader,
        MarketsActions,
        MarketsList,
    },
})
export default class MarketClusterPage extends Mixins(MarketsClusterOtelMixin, ClusterPageMixin) {}
