
import { Prop, Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import moment from 'moment';

import MarketsHistoryService, { MarketsHistoryServiceS } from '@/modules/common/modules/markets-history/markets-history.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';

import Flag from '@/modules/common/components/ui-kit/flag.vue';
import Day from '@/modules/common/types/day.type';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import { ASSESSMENT_TYPES, COLOR_NAME } from '@/modules/common/constants';
import PAGES from '@/modules/common/constants/pages.constant';
import MarketsCalendarLine from './markets-calendar-line.vue';
import DayTooltip from '../../_common/day-tooltip.vue';

const DEFAULT_TOOLTIP_DATA = {
    pos: null,
    color: COLOR_NAME.GRAY,
    date: null,
    position: null,
    page: null,
    dayState: null,
};

@Component({
    components: {
        MarketsCalendarLine,
        DayTooltip,
        Flag,
    },
})
export default class MarketsDaySet extends Vue {
    @inject(ClusterServiceS) clusterService!: ClusterService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @inject(ClusterMarketsServiceS) private clusterMarketsService!: ClusterMarketsService;
    @inject(MarketsCommonServiceS) private marketsCommonService!: MarketsCommonService;
    @inject(MarketsHistoryServiceS) private marketsHistoryService!: MarketsHistoryService;

    @Prop({ type: Object, default: () => ({}) })
    hotelData!: ClusterHotelsMarketsModel;

    tooltipData: {
        pos: HTMLElement | null;
        color: COLOR_NAME;
        date: Date | null;
        position: number | null;
        page: number | null;
        dayState: string | null;
    } = DEFAULT_TOOLTIP_DATA;

    isCalendarLoading = false;

    get mainCompset() {
        if (this.skeleton) return null;
        return this.clusterService.getMainCompset(this.hotelData);
    }

    get skeleton() {
        return !this.hotelData.hotelName;
    }

    get hasOtherEvents() {
        if (!this.tooltipData.date) return false;
        const { date } = this.tooltipData;
        const { hotelId } = this.hotelData;
        const { marketId } = this.mainCompset || {};

        if (!marketId) {
            return false;
        }

        return this.eventsManagerService.hasDateOtherEvents(date, hotelId);
    }

    get flagList() {
        if (!this.tooltipData.date) return [];

        const { date } = this.tooltipData;
        const events = this.eventsManagerService.getHolidayEvents(date);

        return Array.from(
            new Set(
                events.map(e => e.countryCode),
            ),
        );
    }

    get isOutOfRange() {
        return this.marketsCommonService
            .isOutOfRange(this.hotelData.compsetMain);
    }

    get flagsMoreBy() {
        return this.flagList.length - 3;
    }

    get isLoading() {
        return this.isCalendarLoading || !this.hotelData.documentLoaded;
    }

    get formatedDate() {
        if (!this.tooltipData.date) return '';
        return moment(this.tooltipData.date).format('ddd, MMM DD, YYYY');
    }

    // Lazy load of document, send request only if component is in viewport.
    async loadViewportedHotel(isVisible: boolean) {
        if (this.isCalendarLoading || !isVisible || this.hotelData.documentLoaded) {
            return;
        }

        this.isCalendarLoading = true;
        this.clusterService
            .loadDaysData(PAGES.MARKETS, this.hotelData.hotelId)
            .finally(() => {
                this.isCalendarLoading = false;
            });
    }

    setActiveDay(el: HTMLDivElement) {
        const { year, month } = this.documentFiltersService;
        const { hotelId, compsetMain } = this.hotelData;

        const day = +el.dataset.day! as Day;
        const assessment = +el.dataset.type! as ASSESSMENT_TYPES;

        const modificators: { [k: number]: COLOR_NAME } = {
            [ASSESSMENT_TYPES.GOOD]: COLOR_NAME.DARK_GREEN,
            [ASSESSMENT_TYPES.NORMAL]: COLOR_NAME.GREEN,
            [ASSESSMENT_TYPES.FAIR]: COLOR_NAME.YELLOW,
            [ASSESSMENT_TYPES.BAD]: COLOR_NAME.RED,
            [ASSESSMENT_TYPES.NO_DATA]: COLOR_NAME.WHITE,
            [!this.isNa(day) ? ASSESSMENT_TYPES.SOLD_OUT : -1]: COLOR_NAME.GRAY,
            [this.isNa(day) ? ASSESSMENT_TYPES.SOLD_OUT : -1]: COLOR_NAME.RED,
        };

        let dayState: string | null = null;

        if (this.isOutOfRange) {
            dayState = this.$tc('outOfRange');
        } else if (this.clusterMarketsService.isNA(day, hotelId)) {
            dayState = this.$tc('na');
        } else if (this.clusterMarketsService.isNoData(day, hotelId)) {
            dayState = this.$tc('noData');
        }

        const position = compsetMain
            ? this.clusterMarketsService.getPosition(day, hotelId, compsetMain)
            : null;

        const page = compsetMain
            ? this.clusterMarketsService.getPage(day, hotelId, compsetMain)
            : null;

        const date = new Date(Date.UTC(year, month, day));

        const newTooltipData = {
            pos: el,
            color: modificators[assessment],
            date,
            position,
            page,
            dayState,
        };

        this.tooltipData = newTooltipData;
    }

    hideTooltip() {
        this.tooltipData = DEFAULT_TOOLTIP_DATA;
    }

    openPopup(el: HTMLElement) {
        if (!this.mainCompset) return;

        const day = +el.dataset.day! as Day;
        const source = this.clusterService.currentMarketsProvider;

        if (this.marketsCommonService.isOutOfRange(this.hotelData.compsetMain)) {
            return;
        }

        this.marketsHistoryService.setMarketsData(this.hotelData.compsetMain || null, {
            provider: source,
        });

        this.documentFiltersService.compsetId = this.mainCompset.id;

        this.$router.push({
            name: `${this.$route.name!}.day-markets-source`,
            params: {
                day: String(day),
                hotelId: String(this.hotelData.hotelId),
                compsetId: this.mainCompset.id,
                source,
            },
        });
    }

    private isNa(day: Day) {
        return this.clusterMarketsService
            .isNA(day, this.hotelData.hotelId);
    }
}
